<template>
  <router-link :to="{ name: 'book', params: { id }}" class="book">
    <div
      class="book__cover"
      :style="{ backgroundColor: !coverImage && coverColor ? coverColor : '#53565B' }"
    >
      <img v-if="coverImage" :src="coverImage" alt="" />
      <template v-else>
        <div class="book__title book__title_light book__title_without-margin book__title_center">{{ title }}</div>
        <hr class="book__divider" />
        <div class="book__author book__author_light">{{ author }}</div>
      </template>
    </div>
    <div class="book__info">
      <div>
        <div class="book__tags">
          <book-tag
            v-for="tag in tags"
            :key="tag.title"
            :tag="tag.title"
          />
        </div>
        <div class="book__title book__title_short">{{ title }}</div>
        <div class="book__author book__author_short">{{ author }}</div>
        <div
          class="book__category"
          @click.prevent="handleCategoryClick"
        >
          {{ category?.title ? `#${category.title}` : '' }}
        </div>
      </div>
      <div
        v-if="slicedReviews.length"
        class="book__reviews reviews"
      >
        <b-avatar
          v-for="review in slicedReviews"
          :key="review.employee_uid"
          size="20px"
          variant="info"
          class="reviews__item"
          :src="review.employee.photo"
        />
        <div v-if="reviewsLastCount" class="reviews__count">{{ reviewsLastCount }}</div>
      </div>
    </div>
  </router-link>
</template>

<script>
import BookTag from './book-tag.vue';

const COUNT_OF_SHOWED_REVIEWS = 10;

export default {
  name: 'Book',
  components: { BookTag },
  props: {
    id: {
      type: Number
    },
    coverColor: {
      type: String,
      default: ''
    },
    coverImage: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      default: () => {}
    },
    reviews: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCategoryClick() {
      this.$emit('onCategoryClick', this.category);
    }
  },
  computed: {
    likedReviews() {
      return this.reviews.filter(({ is_like }) => is_like);
    },
    slicedReviews() {
      return this.likedReviews.slice(0, COUNT_OF_SHOWED_REVIEWS);
    },
    reviewsLastCount() {
      return this.slicedReviews.length >= COUNT_OF_SHOWED_REVIEWS ? this.likedReviews.length - this.slicedReviews.length : 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.book {
  width: 100%;
  height: 200px;
  display: flex;
  cursor: pointer;
  background: #fff;
  border-radius: 10px;

  &__cover {
    width: 140px;
    height: 100%;
    display: flex;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e2e2e5;

    img {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      margin: -10px;
      object-fit: cover;
    }
  }

  &__info {
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 140px);
    justify-content: space-between;
  }

  &__tags {
    gap: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    color: #2A3042;
    font-size: 13px;
    margin-top: 15px;
    font-weight: 700;
    line-height: 15px;

    &_light {
      color: #fff;
    }

    &_center {
      text-align: center;
    }

    &_without-margin {
      margin: 0;
    }

    &_short {
      max-width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__divider {
    height: 1px;
    border: none;
    width: 100%;
    margin: 10px 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.20);
  }

  &__author {
    margin-top: 5px;
    font-size: 13px;
    color: #2D3338;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    &_light {
      color: rgba(255, 255, 255, 0.60);
    }

    &_short {
      max-width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__cover &__author {
    margin: 0;
    font-weight: 700;
    line-height: 20px;
  }

  &__category {
    margin-top: 5px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(#343A40, .4);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__reviews {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &__info &__category {
    user-select: none;
  }

  &__info &__title:hover,
  &__info &__category:hover  {
    color: #556EE6;
  }
}
.reviews {
  display: flex;
  padding-top: 10px;
  align-items: center;

  &__item {
    border-radius: 20px;
    border: 2px solid #FFF;
  }

  &__item + &__item {
    margin-left: -6px;
  }

  &__count {
    z-index: 1;
    height: 20px;
    display: flex;
    color: #fff;
    font-size: 10px;
    padding: 2px 7px;
    font-weight: 500;
    margin-left: -6px;
    line-height: 15px;
    font-style: normal;
    align-items: center;
    border-radius: 20px;
    background: #28A745;
    border: 2px solid #FFF;
  }
}
</style>
