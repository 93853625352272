<template>
  <b-dropdown
    left
    ref="dropdown"
    variant="outline-secondary"
    text="Offset Dropdown"
    class="sorting-filter"
  >
    <template #button-content>
      <div class="sorting-filter__content">
        <div>{{ title }}</div>
        <i class="bx bxs-down-arrow" />
      </div>
    </template>
    <b-dropdown-form>
      <b-form-group class="m-0" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="selectedLanguage"
          :options="languagesOptions"
          :aria-describedby="ariaDescribedby"
          stacked
        />
      </b-form-group>
    </b-dropdown-form>
    <b-dropdown-divider />
    <div
      class="sort-by"
      @click="setSort('newest')"
    >
      <div class="sort-by__name">Newest</div>
      <i v-if="sort === 'newest'" class="bx bx-check sort-by__icon" />
    </div>
    <div
      class="sort-by"
      @click="setSort('most_popular')"
    >
      <div class="sort-by__name">Most popular</div>
      <i v-if="sort === 'most_popular'" class="bx bx-check sort-by__icon" />
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: 'LibrarySortingFilter',
  props: {
    sort: String,
    language: Number,
  },
  methods: {
    setSort(sort) {
      this.$emit('onSortChange', this.sort === sort ? '' : sort);
      this.$refs.dropdown.hide(true);
    },
  },
  computed: {
    title() {
      if (!this.sort) {
        return 'Sort By';
      }
      const str = this.sort.replace('_', ' ');
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    selectedLanguage: {
      set(value) {
        this.$emit('onLanguageChange', value);
      },
      get() {
        return this.language;
      }
    },
    languages() {
      return this.$store.state.library.languages;
    },
    languagesOptions() {
      return [
        { text: 'All Languages', value: null },
        ...this.languages.map((item) => ({ text: item.title, value: item.id }))
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
.sorting-filter {
  width: 100%;
  :deep(.dropdown-toggle) {
    padding: 0;
    background-color: #fff;
    border: 1px solid #E3E4E8;

    &:active {
      background-color: #fff;
      border: 1px solid #E3E4E8;
    }
  }

  &.show &__content i {
    transform: rotate(180deg);
  }

  &__content {
    height: 36px;
    display: flex;
    padding: 0px 15px 0px 10px;
    align-items: center;
    justify-content: space-between;
    color: #343A40;
    font-size: 13px;
    line-height: 20px;

    i {
      font-size: 8px;
    }
  }
}
:deep(.custom-radio + .custom-radio) {
  margin-top: 10px;
}
:deep(.dropdown-menu) {
  min-width: 200px;
}
.b-dropdown-form {
  padding: 0;
}
:deep(.dropdown-menu) {
  padding: 10px 20px;
}
.dropdown-divider {
  margin: 10px 0px;
}
.sort-by {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &__name {
    color: #2A3042;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__icon {
    font-size: 20px;
    color: #556EE6;
  }
}
.sort-by + .sort-by {
  margin-top: 10px;
}
</style>
