<template>
  <div class="library-search">
    <i class="bx bx-search library-search__search-icon" />
    <i
      v-if="input"
      class="mdi mdi-close-circle library-search__clear-icon"
      @click="input = ''"
    />
    <b-form-input
      v-model="input"
      placeholder="Title, Author"
    />
  </div>
</template>

<script>
export default {
  name: 'LibrarySearch',
  props: {
    search: {
      type: String,
      required: true
    }
  },
  computed: {
    input: {
      set(value) {
        this.$emit('onChange', value);
      },
      get() {
        return this.search;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.library-search {
  position: relative;

  .form-control {
    width: 280px;
    height: 36px;
    padding: 0px 30px 0px 36px;
  }

  &__search-icon {
    top: 50%;
    left: 10px;
    color: #2A3042;
    font-size: 16px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__clear-icon {
    top: 50%;
    right: 5px;
    opacity: 0.3;
    cursor: pointer;
    color: #2A3042;
    font-size: 20px;
    position: absolute;
    transform: translateY(-50%);
  }
}
</style>
