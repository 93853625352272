<template>
  <b-dropdown
    left
    ref="dropdown"
    variant="outline-secondary"
    class="category-filter"
  >
    <template #button-content>
      <div class="category-filter__content">
        <div>{{ category.title }}</div>
        <i class="bx bxs-down-arrow" />
      </div>
    </template>
    <div
      class="category"
      @click="selectAllCategories"
    >
      <div class="category__name">All Categories</div>
      <i v-if="category.id === null" class="bx bx-check category__icon" />
    </div>
    <div
      v-for="cat in proCategories"
      :key="cat.id"
      class="category"
      @click="selectCategory(cat)"
    >
      <div class="category__name">{{ cat.title }}</div>
      <i v-if="category.id === cat.id" class="bx bx-check category__icon" />
    </div>
    <b-dropdown-divider v-if="proCategories.length && commonCategories.length" />
    <div
      v-for="cat in commonCategories"
      :key="cat.id"
      class="category"
      @click="selectCategory(cat)"
    >
      <div class="category__name">{{ cat.title }}</div>
      <i v-if="category.id === cat.id" class="bx bx-check category__icon" />
    </div>
  </b-dropdown>
</template>

<script>
import { ALL_CATEGORIES_OPTION } from '@/utils/constants';

export default {
  name: 'LibraryCategoryFilter',
  props: {
    category: {
      type: Object
    }
  },
  methods: {
    selectCategory(category) {
      this.$refs.dropdown.hide(true);
      this.$emit(
        'onChange',
        this.category.id === category.id ? ALL_CATEGORIES_OPTION : category
      );
    },
    selectAllCategories() {
      this.$refs.dropdown.hide(true);
      this.$emit('onChange', ALL_CATEGORIES_OPTION);
    },
  },
  computed: {
    categories() {
      return this.$store.state.library.categories;
    },
    proCategories() {
      return this.categories.filter(({ is_pro }) => is_pro);
    },
    commonCategories() {
      return this.categories.filter(({ is_pro }) => !is_pro);
    }
  },
  
}
</script>

<style lang="scss" scoped>
.category-filter {
  width: 100%;
  :deep(.dropdown-toggle) {
    padding: 0;
    background-color: #fff;
    border: 1px solid #E3E4E8;

    &:active {
      background-color: #fff;
      border: 1px solid #E3E4E8;
    }
  }

  &.show &__content i {
    transform: rotate(180deg);
  }

  &__content {
    height: 36px;
    display: flex;
    padding: 0px 15px 0px 10px;
    align-items: center;
    justify-content: space-between;
    color: #343A40;
    font-size: 13px;
    line-height: 20px;

    i {
      font-size: 8px;
    }
  }
}
.custom-radio + .custom-radio {
  margin-top: 10px;
}
:deep(.dropdown-menu) {
  min-width: 200px;
  max-height: 300px;
  overflow-y: scroll;
}
.b-dropdown-form {
  padding: 0;
}
:deep(.dropdown-menu) {
  padding: 10px 20px;
}
.dropdown-divider {
  margin: 10px 0px;
}
.category {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &__name {
    color: #2A3042;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__icon {
    font-size: 20px;
    color: #556EE6;
  }
}
.category + .category {
  margin-top: 10px;
}
</style>
