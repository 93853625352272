<template>
  <div class="book-slider">
    <vue-slick-carousel v-bind="settings">
      <router-link
        v-for="book in books"
        :key="book.id"
        :to="{ name: 'book', params: { id: book.id }}"
        class="book"
        @mousedown.prevent="handleMouseDown"
        @mouseup.prevent="handleMouseUp(book.id)"
      >
        <div
          class="book__cover"
          :style="{ backgroundColor: !book.photoUrl ? book.cover_color : '#53565B' }"
        >
          <img v-if="book.photoUrl" :src="book.photoUrl" alt="" />
          <template v-else>
            <div class="book__name">{{ book.title }}</div>
            <hr class="book__divider" />
            <div class="book__author">{{ book.author }}</div>
          </template>
        </div>
        <div class="book__tags">
          <book-tag
            v-for="tag in book.labels"
            :key="tag.title"
            :tag="tag.title"
          />
        </div>
        <div class="book__title">{{ book.title }}</div>
      </router-link>
      <template #prevArrow>
        <button class="custom-arrow">
          <i class="bx bx-chevron-left" />
        </button>
      </template>
      <template #nextArrow>
        <button class="custom-arrow">
          <i class="bx bx-chevron-right" />
        </button>
      </template>
    </vue-slick-carousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import BookTag from './book-tag.vue';

export default {
  name: 'BookSlider',
  components: {
    BookTag,
    VueSlickCarousel
  },
  props: {
    books: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    settings: {
      infinite: true,
      speed: 500,
      dots: true,
      arrows: true,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 400,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    },
    timerId: null,
    draggable: false
  }),
  methods: {
    handleMouseDown() {
      this.timerId = setTimeout(() => {
        this.draggable = true;
      }, 200);
    },
    handleMouseUp(id) {
      clearTimeout(this.timerId);
      if (this.draggable) {
        this.draggable = false;
      } else {
        this.$router.push(`/library/${id}`);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.book-slider {
  padding-bottom: 85px;
  :deep(.slick-arrow) {
    top: 115px;
    z-index: 2;
    width: 40px;
    height: 40px;
    background: #000;
    border-radius: 50%;
    background-image: none !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &::before {
      display: none;
    }

    i {
      color: #fff;
      font-size: 26px;
    }

    &.slick-prev {
      left: -20px;
    }

    &.slick-next {
      right: -20px;
    }
  } 
  :deep(.slick-dots) {
    gap: 5px;
    bottom: -40px;
    display: flex !important;
    justify-content: center;

    li {
      margin: 0;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: 6px;
        height: 6px;
        padding: 0px;
        margin: 0px;
        border-radius: 50%;
        background: #dfdfe1;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: #556ee6;
        }
      }
    }
  }
}
.book {
  margin: 0 auto;
  cursor: pointer;
  width: 160px !important;
  display: block !important;

  &__cover {
    width: 100%;
    display: flex;
    height: 230px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e2e2e5;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  &__name {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }
  &__divider {
    height: 1px;
    border: none;
    margin: 10px 0px;
    text-align: center;
    width: calc(100% - 20px);
    background: rgba(255, 255, 255, 0.20);
  }
  &__author {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.60);
  }
  &__tags {
    gap: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: center;
  }
  &__title {
    color: #2A3042;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;

    &:hover {
      color: #556EE6;
    }
  }
  @media (max-width: 400px) {
    width: 280px !important;

    &__cover {
      height: 400px;
    }
  }
}
</style>